import React from 'react';
import { KeyValueList, PictureParagraph, Table } from '../components';
import grevToken from 'images/Properties/grevToken.svg';
import { Box } from '@mui/system';
import Container from 'components/Container';
import { TabTitle } from './Styles';
import Piechart from 'images/Properties/tokenomics/piechart.svg';
import Disclaimer from '../components/Disclaimer';
import { TOKEN_CONTRACT_ADDRESS } from 'layouts/Properties/components/KeyToActionBar/KeyToActionBar';
import {
  parseStringToArray,
  useLocalise,
  useLocaliseKeyValueList,
  useRemapper,
} from 'hooks/use-localise';
import { KeyValueList as KeyValueListType } from '../components/KeyValueList/KeyValueList';
import { Table as TableType } from '../components/Table/Table';

const KeyValueListContent = {
  grev55token: {
    id: 1,
    title: 'grev55token',
    // Here we only specify overrides or additions to translations - eg. which translation to override by index
    rows: [
      'grev55token',
      {
        translationIndex: 0,
        translationOverride: ['', grevToken],
      },
      {
        translationIndex: 3,
        link: `https://etherscan.io/token/${TOKEN_CONTRACT_ADDRESS}`,
      },
    ],
  },
};

const TableContent = {
  tokenDistribution: {
    id: 1,
    title: 'tokenDistribution',
    rows: ['tokenDistribution'],
  },
};

const pictureParagraphContent = {
  pieChart: {
    id: 1,
    title: '',
    altTxt: '',
    alt: 'pieChart',
    imageSrc: Piechart,
  },
};
const TokenomicsTab = (): JSX.Element => {
  // Establishing localise with viewname and sectionName
  const { localise } = useLocalise('Properties', 'Tokenomics');
  const remapper = useRemapper(localise);

  // Establishing localise keyValueList specific remapper
  const localiseKeyValueList = (
    sectionName: keyof typeof KeyValueListContent,
  ) =>
    useLocaliseKeyValueList(
      KeyValueListContent[sectionName] as KeyValueListType,
      remapper,
    );

  // Establishing localise Table specific remapper
  const localiseTable = (sectionName: keyof typeof TableContent) => {
    const { rows, ...restContent } = remapper(
      TableContent[sectionName] as unknown as TableType,
      ['title', 'rows'],
    );

    return {
      ...restContent,
      rows:
        typeof rows === 'string'
          ? (parseStringToArray(rows) as string[][])
          : rows,
    };
  };

  // Establishing localise PictureParagraph specific remapper
  const localisePictureParagraph = (
    sectionName: keyof typeof pictureParagraphContent,
  ) => remapper(pictureParagraphContent[sectionName], ['alt']);

  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'column', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <TabTitle>{localise('title')}</TabTitle>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          justifyContent: 'space-between',
          gap: '52px',
        }}
      >
        <KeyValueList content={localiseKeyValueList('grev55token')} />
        <PictureParagraph
          content={localisePictureParagraph('pieChart')}
          sx={{
            maxWidth: { md: '42%', sm: '100%', xs: '100%' },
            paddingTop: { md: '89px', sm: '24px', xs: '24px' },
            justifyContent: 'center',
          }}
        />
      </Box>
      <Table content={localiseTable('tokenDistribution')} />
      <Disclaimer />
    </Container>
  );
};

export default TokenomicsTab;
