import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import { StyledPropsFix } from 'types';

export const ProgressStyles = styled(Box)<{ calculatedPercent: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  progress {
    opacity: 0;
  }

  .progress-element {
    width: 573px;
    /* margin: 8px 0 16px 0; */
    padding: 0 20px;
  }

  .progress-container {
    position: relative;
    background: #e3e3e3;
    height: 8px;
    border-radius: 10px;
    overflow: hidden;
  }

  .progress-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 10px;
    background: #5ca8e4;
  }

  .progress-element--html .progress-container::before {
    animation: progress-html 1s ease-in forwards;
  }

  .progress-label {
    font-family: 'Roboto Regular';
    position: relative;
    display: flex;
    justify-content: space-between;
    color: #4f5b69;
    font-weight: 400;
    font-size: 14px;
    align-items: flex-end;

    p {
      margin: 16px 0 8px 0;
    }
  }

  .tokens-left {
    position: relative;
    color: #4f5b69;
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    margin: 8px 0 16px 0;
  }

  .token-count {
    font-family: 'Roboto Bold';
    color: #1c5695;
    font-weight: 500;
    padding-right: 10px;
  }

  .token-sold {
    font-size: 16px;
    font-family: 'Roboto Bold';
    color: #1c5695;
    font-weight: 500;
  }

  @property --num {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
  }

  .progress-label::after {
    counter-reset: num var(--num);
    content: counter(num) '%';
    position: absolute;
    top: 65px;
    left: 0;
    color: #1c5695;
    font-weight: 500;
    font-size: 14px;
  }

  .progress-element--html .progress-label::after {
    animation: progress-text-html 1s ease-in forwards;
  }

  @keyframes progress-html {
    to {
      width: ${({ calculatedPercent }) => calculatedPercent}%;
    }
  }

  @keyframes progress-text-html {
    to {
      --num: ${({ calculatedPercent }) => calculatedPercent};
    }
  }
`;

export const ActionButton = styled(Button)`
  text-transform: none;
  font-family: Roboto Regular;
  color: ${({ theme }) => theme.palette.text.secondary};
  width: min-content;
  align-self: center;
  white-space: nowrap;
  margin-left: 28px;
  padding: 8px 24px;

  &:hover {
    background-color: #58a88f;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 1.125em;
    margin: 0;
    transform: scale(0.7);
  }
` as StyledPropsFix;
