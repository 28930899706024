import { Main, PropertiesWrapper } from 'layouts';
import React from 'react';
import TokenomicsTab from 'views/Properties/Tabs/TokenomicsTab';
import { useTheme } from '@mui/material/styles';
import { SEO as Head } from 'components/seo';

const TokenomicsTabPage = (): JSX.Element => {
  const theme = useTheme();
  return (
    <>
      <Head />
      <Main bgcolor={theme.palette.background.default}>
        <PropertiesWrapper>
          <TokenomicsTab />
        </PropertiesWrapper>
      </Main>
    </>
  );
};

export default TokenomicsTabPage;
